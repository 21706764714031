/**
 * @file sensitive-information.js
 * @description Se manejan las excepciones de la mascara de información sensible para casos unicos
 * como la solucion de enmascaramiento es completamente css, se crea este archivo para manejar
 * las excepciones y mantener la lógica de la mascara en el archivo de estilos
 */

import onmount from 'onmount';

/**
 * @description Se encarga de agregar la clase show a los elementos que
 * contienen el atributo data-sensitive-mask-ignore para ser visibles por el usuario pero ignorado por el enmascaramiento
 * de clarity
 */
onmount('.sensitive-information', function () {
  const self = this;
  if (self.dataset.sensitiveMaskIgnore !== undefined) {
    self.classList.add('show');
  }
});
